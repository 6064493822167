import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const _ = require('lodash');
const moment = require('moment');
const uuidv4 = require('uuid/v4');

const ReviewModule = ({ data }) => (
  <li className={`row${data.sideBar ? ' reviews-row-side' : ' reviews-row'}`} key={uuidv4()}>
    <div className={data.sideBar ? 'col-12' : 'col-sm-12 col-md'}>
      <Link to={data.slug}><Img fluid={data.teaserimg} alt="teaser for review" /></Link>
    </div>
    <div className={data.sideBar ? 'col-12' : 'col-sm-12 col-md mt-3 mt-md-0'}>
      <Link className="review-title" to={data.slug}>{data.title}</Link>
      <br />
      <p className="tags">
        {
          data.tags.map((tag, index) => {
            const link = `/tags/${_.kebabCase(tag)}/`;
            return (
              <span key={`${tag}-${uuidv4()}`}>
                <Link className="tag-link" to={link}>
                  {tag}
                  {index === data.tags.length - 1 ? '' : ', '}
                </Link>
              </span>
            );
          })
        }
      </p>
      <p className="review-module-date">
        {moment(data.date).format('MMM Do YYYY')}
        {' | '}
        {_.capitalize(data.type)}
      </p>
      <p className={data.sideBar ? 'd-none' : 'excerpt'}>
        {data.snippet}
      </p>
    </div>
    <hr />
  </li>
);

ReviewModule.propTypes = {
  data: PropTypes.shape({
    sideBar: PropTypes.bool,
    slug: PropTypes.string,
    type: PropTypes.string,
    teaserimg: PropTypes.shape({
    }),
    title: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    snippet: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
};

export default ReviewModule;
